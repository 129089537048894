<template>
  <dash-page-new
      :title="$t('Contacts')"
      :filters="filters || []"

      :filters-input="filtersData || {}"
      @filter="filtersData = $event"
  >

<!--    AJAX Search for Pagination-->
<!--    :filters-search-ajax="$store.state.settings.pageData.filtersSearchAjax || null"-->
<!--    :filters-search="$store.state.settings.pageData.filtersSearch || false"-->

    <template #header_action>
      <ws-button
          @click="openNewContact"
          label="contacts.add"
          left-icon="mdi-plus"
      />
    </template>

    <template #default>


      <ws-navigation-header
          class="mt-5"
          :items="navigationSelect"
          v-model="navigation"
          right
          no-number
      />

      <div class="d-flex flex-row mt-5">

        <!-- Lists table-->
        <v-sheet class="overflow-hidden mr-4" style="border: 1px solid var(--wsBACKGROUND); border-radius : 4px; transition: width 0.3s ease"
                 :style="`width : ${collapseLists ? '47' : '300'}px`"

        >

          <v-sheet class="d-flex align-center justify-space-between px-2" :color="wsLIGHTCARD" height="47">
            <div v-if="!collapseLists" class="d-flex align-center">
              <v-btn @click="openAddList" class="mr-3" icon>
                <v-icon :color="wsACCENT" >mdi-folder-plus-outline</v-icon>
              </v-btn>
              <h5 class="wsACCENT">{{ $t('Lists') }}</h5>
            </div>
            <v-btn @click="handleCollapse" icon>
              <v-icon :color="wsACCENT" >mdi-arrow-collapse-{{ collapseLists ? 'right' : 'left' }}</v-icon>
            </v-btn>

          </v-sheet>
          <ws-data-table
              v-if="!collapseLists"
              :items="listsFiltered"
              :headers="liststHeaders"
              :selected-row="selectedList"
              no-footer
              no-header
              disable-pagination
              style="border-top: 1px solid var(--wsBACKGROUND);border-bottom: 1px solid var(--wsBACKGROUND);"
              :row-action="(item) => { selectedList = item.uuid}"
          >

            <template #item.name="{item,hover}">

              <div class="d-flex align-center justify-space-between">
                <h5 class="wsACCENT shorten-text" style="min-width: 200px; font-size: 12px">{{ item.name }}</h5>
                <h5 v-if="!hover || !item.uuid || item.uuid === 'no_list' " class="wsDARKER">{{ item.contacts_count }}</h5>
                <v-btn v-if="hover && !!item.uuid &&  item.uuid !== 'no_list'" @click="openEditList(item)" class="mr-n2" small  icon>
                  <v-icon size="18" :color="wsACCENT">mdi-pencil</v-icon>
                </v-btn>
              </div>

            </template>


          </ws-data-table>
        </v-sheet>

        <!-- Contacts table-->
        <ws-data-table
            class="flex-grow-1"
            :items="itemsFiltered"
            :headers="headers"
            :search="filtersData.search"
            selectable
            :selected-rows="selectedContacts"
            @select="selectedContacts = $event.items"
            :multiple-actions="multipleActionsSelect"
            bordered
        >

          <template #item.name="{item}">
            <div class="d-flex align-center " >
              <v-icon  :color="getItemColor(item)">mdi-circle-medium</v-icon>
              <h5 class="wsDARKER text-no-wrap mr-2" style="font-size: 12px; ">{{ item.name }} {{ item.lastname }}</h5>
            </div>

          </template>

          <template #item.tags="{item}">
            <ft-select
                :ref="item.uuid + 'tags'"
                :items="item.tags"
                :disabled="item.tags.length < 2"
            >
              <template #icon="{item}">
                <v-icon class="mr-2" :color="item.color">mdi-circle</v-icon>
              </template>
              <v-sheet
                  v-if="item.tags && item.tags.length > 0"
                  :class="[{pointer : item.tags.length > 1}]"
                  :color="item.tags[0].color"
                  class="px-2 py-1 wsRoundedLight"
                  dark
              >
                <h5 class="d-flex align-center font-weight-regular">
                  {{ item.tags[0].text }}
                  <span v-if="item.tags.length > 1">+{{item.tags.length-1}}</span>
                </h5>
              </v-sheet>
            </ft-select>
          </template>



        </ws-data-table>


      </div>


    </template>

    <template #dialog>

      <!-- Contact Dialog -->
      <ws-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          @save="addEditContact"
          :title="$t('mailer.campaigns.create')"
          :save-text="$t('Next')"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />
        <ws-text-field
            v-model="entityData.email"
            :label="$t('Email')"
            :placeholder="$t('EnterName')"
            class="mt-5"
        />
        <ws-phone-input
            v-model="entityData.phone"
            :label="$t('Phone')"
            :placeholder="$t('EnterName')"
            class="mt-5"
        />

      </ws-dialog>
      <!-- List Dialog -->
      <ws-dialog
          v-if="displayListDialog"
          v-model="displayListDialog"
          @save="addEditList"
          :title="$t(`contacts.lists.${newList ? 'add' : 'edit'}`)"
          :save-text="newList ? $t('Add') : $t('Save')"
          :show-delete="!newList && listEntityData.uuid !== 'no_list'"
          @delete="deleteList"
      >
        <ws-text-field
            v-model="listEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

      </ws-dialog>

      <!-- Move to List Dialog -->
      <ws-dialog
          v-if="displayMoveToListDialog"
          v-model="displayMoveToListDialog"
          @save="moveToList"
          :title="$t(`contacts.lists.${removeFromList ? 'remove_from' : 'add_to'}`)"
          :save-text="$t('Save')"
      >
        <ws-select
            v-if="!removeFromList || (removeFromList && selectedList === 'all')"
            v-model="moveToListEntityData.contact_list_id"
            :items="listsSelect"
            :label="$t('List')"
            :placeholder="$t('contacts.lists.select')"
            clearable
        />

        <div v-if="removeFromList && selectedList !=='all'">
          <h4 class="font-weight-regular">{{ $t('contacts.lists.remove_from_confirm') }}</h4>
        </div>

      </ws-dialog>
      <!-- Tags Dialog -->
      <ws-dialog
          v-if="displayTagDialog"
          v-model="displayTagDialog"
          @save="setTag"
          :title="$t(`contacts.tags.set`)"
          :save-text="$t('Save')"
      >
        <ws-select
            v-model="tagEntityData.tag_id"
            :items="tagsSelect"
            :label="$t('Tag')"
            :placeholder="$t('ChooseList')"
            allow-new-data
            autocomplete
            new-data-text="AddTag"
            clearable
        />

        <ws-system-color-select
            v-if="tagEntityData.tag_id && tagEntityData.tag_id.includes('new-data')"
            v-model="tagEntityData.color"
            label="ChooseTagColor"
            class="mt-5"
        />

      </ws-dialog>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "mailerСontacts",
  data() {
    return {
      displayDialog : false,
      selectedItem : {},
      entityData : {},
      items : [],
      lists : [],
      filtersData : {},
      navigation : 'contacts',
      liststHeaders : [{value : 'name'}],
      displayListDialog : false,
      listEntityData : {},
      collapseLists : false,
      newList : false,
      selectedList : 'all',
      selectedContacts : [],
      displayMoveToListDialog : false,
      displayTagDialog : false,
      moveToListEntityData : {},
      tagEntityData : {},
      tagsSelect : [],
      removeFromList : false
    }
  },
  computed : {
    filters() {
      return [
        { text : this.$t('Role')  , value : 'status' , type : 'select' , items : [
            { text : 'Student' , value : 'student' },
            { text : 'Manager' , value : 'manager' },
            { text : 'Lead' , value : 'lead' }
          ] },
        { text : this.$t('Course')  , value : 'business_plan' , type : 'select' , items : [] },
      ]
    },
    listsSelect() {
      let items = this.lists.map(el => ({text : el.name, value : el.uuid}))
      items.pop()
      return items
    },
    listsFiltered() {
      let items = this.COPY(this.lists)
      items.unshift({ name : this.$t('All') , uuid : 'all' , contacts_count : this.items.length })
      let popNoLists = false
      items.forEach(item => {
        if (item.uuid === 'no_list') {
          item.name = this.$t('contacts.lists.no_list')
          if ( !item.contacts_count ) {
            popNoLists = true
          }
        }
      })
      if (popNoLists) {
        items.pop()
      }
      return items
    },
    itemsFiltered() {
      let items = this.items

      if ( this.navigation === 'contacts') {
        items = items.filter(el => !el.bounced && !el.unsubscribed)
      } else if ( this.navigation === 'unsubscribed') {
        items = items.filter(el => el.unsubscribed)
      } else if ( this.navigation === 'bounced') {
        items = items.filter(el => el.bounced)
      }

      if ( this.selectedList && this.selectedList !== 'all' ) {

        if ( this.selectedList !== 'no_list') {
          items = items.filter(el => el.contact_list_ids.includes(this.selectedList) )
        } else {
          items = items.filter(el => el.contact_list_ids.length === 0 )
        }

      }

      return items
    },
    headers() {
      return [
        { text : this.$t('HumanName') ,   value : 'name' ,   sortable : false},
        { text : this.$t('Email')   ,  value : 'email'   , sortable : false},
        { text : this.$t('Tags') ,   value : 'tags' ,  width : '10px', sortable : false },
        { value : 'action' , width : '10px', sortable : false },
        { value : 'lastname' , align : 'd-none',  sortable : false},
      ]
    },
    multipleActionsSelect() {
      let items =  [
        { text : 'contacts.lists.add_to' ,
          icon : 'mdi-folder-outline',
          action :  this.openMoveToList
        },
        { text : 'contacts.tags.add',
          icon : 'mdi-label-outline',
          action :  this.openSetTags
        },

      ]
      if ( this.lists.length > 1 && !['all','no_list'].includes(this.selectedList) ) {
        items.push({
          text : 'contacts.lists.remove_from',
          icon : 'mdi-folder-minus-outline',
          action :  this.openRemoveFromList
        })
      }

      // if ( this.tagsSelect.length > 0 ) {
      //   items.push({
      //     text : 'contacts.tags.remove',
      //     icon : 'mdi-label-off-outline',
      //     action :  this.openRemoveFromList
      //   })
      // }

      return items

    },
    navigationSelect() {
      return [
        { text : this.$t('Active') , value : 'contacts' },
        { text : this.$t('Unsubscribed') , value : 'unsubscribed' },
        { text : this.$t('Bounced') , value : 'bounced' }
      ]
    },
    actionsSelect() {
      return [
        { text : this.$t('Open') , value : 'view' },
        { text : this.$t('Edit') , value : 'edit'},
        { text : this.$t('Delete') , value : 'delete'}
      ]
    }
  },
  methods : {

    ...mapActions('contacts' , [
      'GET_CONTACTS',
      'ADD_EDIT_CONTACT',
      'ADD_EDIT_CONTACT_LIST',
      'DELETE_CONTACT_LIST',
      'MOVE_TO_CONTACT_LIST',
      'SET_CONTACTS_TAGS',
    ]),

    openRemoveFromList() {
      this.removeFromList = true
      this.displayMoveToListDialog = true
      this.moveToListEntityData = {
        contact_list_id : this.selectedList || null,
        remove : true,
      }

    },

    async setTag() {
      this.tagEntityData.contacts = this.selectedContacts
      let result = await this.SET_CONTACTS_TAGS(this.tagEntityData)
      if ( !result ) {
        return this.ERROR()
      }

      if ( !this.tagEntityData.tag_id.includes('new-data') ) {
        this.items.forEach(item => {

          if (this.selectedContacts.includes(item.uuid) &&
              !item.tags.map(el=>el.value).includes(this.tagEntityData.tag_id)
          ) {
            let tagEntity = this.tagsSelect.find( el=>el.value === this.tagEntityData.tag_id )
            if ( tagEntity) {
              item.tags.push(tagEntity)
            }
          }
        })
      } else {
        this.initPage()
      }


      this.selectedContacts = []
      this.displayTagDialog = false
    },
    openSetTags() {
      this.displayTagDialog = true
      this.tagEntityData = {}
    },

    async moveToList() {
      this.moveToListEntityData.contacts = this.selectedContacts
      let result = await this.MOVE_TO_CONTACT_LIST(this.moveToListEntityData)
      if ( !result ) {
        return this.ERROR()
      }


      this.items.forEach(item => {

        if (this.moveToListEntityData.contacts.includes(item.uuid)) {
          if ( !item.contact_list_ids.includes(this.moveToListEntityData.contact_list_id)) {
            item.contact_list_ids.push(this.moveToListEntityData.contact_list_id)
          }
          if (this.moveToListEntityData.remove && item.contact_list_ids.includes(this.moveToListEntityData.contact_list_id) ) {
            item.contact_list_ids = item.contact_list_ids.filter( el => el !==  this.moveToListEntityData.contact_list_id)
          }
        }

      })
      this.items = this.COPY(this.items)
      this.selectedContacts = []

      this.displayMoveToListDialog = false

      const index = this.lists.findIndex(el=> el.uuid === this.moveToListEntityData.contact_list_id)
      if (index === -1) {
        return
      }

      if (!this.moveToListEntityData.remove) {
        this.lists[index].contacts_count += this.moveToListEntityData.contacts.length
      } else {
        this.lists[index].contacts_count -= this.moveToListEntityData.contacts.length
        if ( this.lists[index].contacts_count < 0 ) {
          this.lists[index].contacts_count = 0
        }
      }


      if ( this.selectedList === 'no_list') {
        this.lists[this.lists.length - 1].contacts_count -= this.moveToListEntityData.contacts.length
      }
      this.lists = this.COPY(this.lists)

      this.selectedList = this.moveToListEntityData.contact_list_id

      let successText = `${this.$t('contacts.lists.moved')}: ${this.list[index].name}`
      this.notify(successText , 'success')
    },
    openMoveToList() {
      this.removeFromList = false
      this.displayMoveToListDialog = true
      this.moveToListEntityData = {}
    },
    async deleteList() {
      let result = await this.DELETE_CONTACT_LIST(this.listEntityData.uuid)
      if ( !result) {
        return this.ERROR()
      }
      const index =  this.lists.findIndex(el => el.uuid === this.listEntityData.uuid)
      if (index === -1) {
        return
      }
      this.lists.splice(index, 1)
      this.displayListDialog = false

    },
    async addEditList() {
      let result = await this.ADD_EDIT_CONTACT_LIST(this.listEntityData)
      if (!result) {
        return this.ERROR()
      }
      if ( this.newList) {
        result.contacts_count = 0
        this.lists.push(result)
      } else {
        let index = this.lists.findIndex(el => el.uuid === result.uuid)
        if (index === -1) {
          return
        }
        this.lists[index] = result
        this.lists = this.COPY(this.lists)
      }

      this.displayListDialog = false
    },
    openEditList(item) {
      this.newList = false
      this.listEntityData = this.COPY(item)
      this.displayListDialog = true
    },
    openAddList() {
      this.newList = true
      this.listEntityData = {}
      this.displayListDialog = true
    },
    handleCollapse() {
      this.collapseLists = !this.collapseLists
    },

    async addEditContact() {
      let result = await this.ADD_EDIT_CONTACT(this.entityData)
      if (!result) {
        return this.ERROR()
      }
      this.items.push(result)
      this.navigation = 'contacts'
      this.displayDialog = false
    },
    
    openNewContact() {
      this.entityData = {}
      this.displayDialog = true
    },

    editAction(action , item) {
      this.notify(item)
    },
    // Technical

    getItemColor(item) {
      if ( item.bounced) {
        return this.wsWARNING
      }
      return item.is_verified_email ? this.wsSUCCESS : this.wsATTENTION
    },

    async initPage() {
      let result = await this.GET_CONTACTS()

      if (!result && result !== true) {
        return this.ERROR()
      }
      this.items = result.items || []
      this.lists = result.lists || []
      this.tagsSelect = result.tags || []
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>